import { FC } from 'react';

import { LoaderContainer } from './loader.styled';

type LoaderProps = {
  light?: boolean;
};

const Loader: FC<LoaderProps> = ({ light }) => {
  return (
    <LoaderContainer className={`${light ? 'light' : ''}`}>
      <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5 0C30.672 0 30 0.784 30 1.75V12.25C30 13.216 30.672 14 31.5 14C32.328 14 33 13.216 33 12.25V1.75C33 0.784 32.328 0 31.5 0ZM10.7288 9C10.2776 9 9.85469 9.20849 9.51634 9.54815C8.82789 10.2345 8.82789 11.3332 9.51634 12.0195L16.9507 19.4852C17.6392 20.1716 18.7952 20.1716 19.4837 19.4852C20.1721 18.7989 20.1721 17.6463 19.4837 16.96L11.9952 9.54815C11.6545 9.20849 11.1776 9 10.7288 9ZM52.2712 9C51.8224 9 51.3455 9.20849 51.0048 9.54815L43.5163 16.96C42.8279 17.6463 42.8279 18.7989 43.5163 19.4852C44.2048 20.1716 45.3608 20.1716 46.0493 19.4852L53.4837 12.0195C54.1721 11.3332 54.1721 10.2345 53.4837 9.54815C53.1453 9.20849 52.7224 9 52.2712 9ZM1.75 30C0.784 30 0 30.672 0 31.5C0 32.328 0.784 33 1.75 33H12.25C13.216 33 14 32.328 14 31.5C14 30.672 13.216 30 12.25 30H1.75ZM50.75 30C49.784 30 49 30.672 49 31.5C49 32.328 49.784 33 50.75 33H61.25C62.216 33 63 32.328 63 31.5C63 30.672 62.216 30 61.25 30H50.75ZM18.2172 43C17.7684 43 17.2914 43.1554 16.9507 43.4967L9.51634 50.9991C8.82789 51.6889 8.82789 52.7929 9.51634 53.4827C10.2048 54.1724 11.3068 54.1724 11.9952 53.4827L19.4837 46.0344C20.1721 45.3447 20.1721 44.1865 19.4837 43.4967C19.1453 43.1554 18.666 43 18.2172 43ZM44.7828 43C44.334 43 43.8547 43.1554 43.5163 43.4967C42.8279 44.1865 42.8279 45.3447 43.5163 46.0344L51.0048 53.4827C51.6932 54.1724 52.7952 54.1724 53.4837 53.4827C54.1721 52.7929 54.1721 51.6889 53.4837 50.9991L46.0493 43.4967C45.7086 43.1554 45.2316 43 44.7828 43ZM31.5 49C30.672 49 30 49.784 30 50.75V61.25C30 62.216 30.672 63 31.5 63C32.328 63 33 62.216 33 61.25V50.75C33 49.784 32.328 49 31.5 49Z"
          fill="url(#paint0_angular)"
        />
        <defs>
          <radialGradient
            id="paint0_angular"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(31.5 31.5) scale(31.5)">
            <stop offset="0.0189134" stopOpacity="0.244084" />
            <stop offset="0.934471" stopColor="white" stopOpacity="0.187047" />
          </radialGradient>
        </defs>
      </svg>
    </LoaderContainer>
  );
};

export default Loader;
